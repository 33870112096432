@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap');


*{
    text-decoration: none!important;
    outline: none!important;
}

.ff-1{
    font-family: 'Poiret One', cursive;
}
.ff-2{
    font-family: 'Montserrat', sans-serif;
}
.ff-3{
    font-family: 'Bellefair', serif;
}

.letter-spacing{
    letter-spacing: 3px;
}

.indexDiv a{
    color: inherit;
}

.cover{
    object-fit: cover;
}
/* 
.closeIcon{
    font-size: 3rem!important;
    color: white;
}
.closeIcon:hover{
    cursor: pointer;
    color: rgb(202, 202, 202)!important;
}

.dialog{
    display: flex;    
    width: 100%!important;
    margin: 0!important;
    padding: 0!important;
}

.dialogBild{
    margin: auto;
    width: auto;
    height: 80vh;
}
.MuiDialog-paper{
    background-color: transparent!important;
    box-shadow: none!important;
} */


.navbarButton{
    border: none;
    outline: none;
    background-color: transparent;
    color: rgb(48, 48, 48);
    transition: 0.3s;
    font-size: larger;
    margin: 0;
}

.navbarButton:hover{
    color: rgb(199, 180, 155);
}

.navBild{
    cursor: pointer;
    transition: 0.3s;
}
.block-small {
    padding: 40px 0;
    min-height: 250px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.startBlock1{
    background: linear-gradient(357deg, rgba(43,43,43,0) 29%, rgba(64,64,64,1) 29%);
}
.startBildDiv img{
    object-fit: cover;
}

.startButton{ 
    text-decoration: none!important;
    color: inherit;
    border: none;
    outline: none;
    background: none;
    border: 1px solid rgb(138, 138, 138);
    padding: 10px 20px;
    transition: 0.2s;
}
.startButton:hover{
    background-color: rgb(219, 219, 219);
    color: black;
}

.startBildHover img{
    transition: 0.2s; 
}

.startBildHover img:hover{
    cursor: pointer;
    filter: brightness(0.4);   
}

.footerIconsDiv svg{
    color: rgb(48, 48, 48);
    cursor: pointer;
    transition: 0.15s;
}
.footerIconsDiv svg:hover{
    color: rgb(138, 138, 138);
}
.footer{
    letter-spacing: 1px;
}

.bio p{
    color: rgba(48, 48, 48, 0.514);
}

.contactInput{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(221, 221, 221)
}



.contactSendButton{ 
    width: 100%;
    text-decoration: none!important;
    color: inherit;
    border: none;
    outline: none;
    background: none;
    border: 1px solid rgb(221, 221, 221);
    padding: 10px 20px;
    transition: 0.2s;
    border-radius: 5px;
}
.contactSendButton:hover{
    background-color: rgb(219, 219, 219);
    color: black;
}
.videoVernagir{
    padding-right: 20px;
    border-right: 3px solid #142c38;
}
.active{
    color: rgb(199, 180, 155);
}

.hidden{
    visibility: hidden!important;
}




/* ###################### loading */
#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

/* ###################### loading */

.drawerDiv {
    min-width: 220px;
}
.drawerDiv svg{
    color: #00000050;
}




/* Galery Images */
.react-photo-gallery--gallery img{
    object-fit: cover;
    transition: 0.1s;
    padding: 5px!important;
    border-radius: 8px;
}
.react-photo-gallery--gallery img:hover{
    filter: brightness(0.6)
}

.react-images__navigation button{
    background-color: transparent!important;

} 

@media screen and (min-width: 520px){
    /* Galery Images */
   .react-images__view-image{
        width: auto;
        height: 95vh!important;
    }
  
} 

