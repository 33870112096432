.about .price-list .package {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 10%;
}

.about .price-list .package p {
    text-align: left;
    text-indent: -10px;
}
@media all and (max-width: 992px) {
    .about .info-wrapper .text {
        margin-left: 0!important;
    }
    .about .info-wrapper div {
        margin-left: 0!important;
    }
}
@media all and (max-width: 992px) and (min-width: 768px),  (max-width: 450px) {
    .about .info-wrapper h4 {
        font-size: 18px;
    }
    .about .info-wrapper .text {
        font-size: 13px;
        margin-left: 0!important;
    }
}
@media all and (max-width: 370px) {
    .about .info-wrapper h4 {
        font-size: 16px;
    }
    .about .info-wrapper .text {
        font-size: 10px;
    }
}

